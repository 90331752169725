import React, { Component } from "react"
import { Message } from "semantic-ui-react"
import styled from "styled-components"

const CajaSticky = styled.div`
@media (max-width: 600px){
    width: 300px;
    height: 100px;
    top: 20%;
    left: 50%;
    margin-top: -50px;
    margin-left: -150px; 
}
position: fixed;
width: 500px;
height: 100px;
top: 20%;
left: 50%;
margin-top: -50px;
margin-left: -250px;
`;


class ScreenMessage extends Component {

    render() {
        return (
            <CajaSticky style={this.props.errormsg || this.props.successmsg ? { zIndex: 9 } : { zIndex: 0 }} >
                {this.props.errormsg ?
                    <Message negative size="massive" >
                        <Message.Header>ERROR</Message.Header>

                        <Message.Header>{this.props.errormsg}</Message.Header>
                    </Message>
                    : ""}
                {this.props.successmsg ?
                    <Message positive size="massive" >
                        <Message.Header></Message.Header>

                        <Message.Header>{this.props.successmsg}</Message.Header>
                    </Message>
                    : ""}
            </CajaSticky>
        )
    }
}

export default ScreenMessage