import React, { Component } from "react";
// import { animateScroll as scroll } from 'react-scroll'
import "./css/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MenuWithRouter from "./components/Menu";
import styled from "styled-components";
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/authActions";
import BuscarPorDNI from "./sections/BuscarPorDNI/BuscarPorDNI";
import Comparador from "./sections/Comparador/Comparador";
import UploadApp from "./sections/Upload/UploadApp";
import BalanceApp from "./sections/Balance/BalanceApp"
import Admin from "./sections/Admin/Admin";

const CajaConTodo = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Main = styled.div`
@media (min-width: 901px) {
  font-size: 1.3vh;}
@media ( max-width: 900px) {
  font-size: 1.3vh;}


  overflow: auto;
  overflow-x: hidden;

  display: flex;
  color: black;
  flex-flow: column;
  flex-basis: 100%;
  flex-grow: 1;
  align-content: center;
  padding-bottom: 5%;
`;

//const ScrollUp = styled.div`
//display: flex;
//position: sticky;
//bottom: 30px
//z-index:10
//`;

class App extends Component {
  //constructor() {
  //  super()
  //  this.scrollToTop = this.scrollToTop.bind(this);
  //}
  //  scrollToTop() {
  //    scroll.scrollToTop();
  //  }
  componentDidMount() {
    store.dispatch(loadUser());
  }
  render() {
    return (
      <Provider store={store}>
        <CajaConTodo className="Fondo">
          <Router>
            <MenuWithRouter />
            <Main>
              <Switch>
                <Route path="/buscarpordni" component={BuscarPorDNI} />
                <Route path="/upload" component={UploadApp} />
                <Route path="/balance" component={BalanceApp} />
                <Route path="/admin" component={Admin} />
                <Route path="/" component={Comparador} />
              </Switch>
            </Main>
          </Router>
        </CajaConTodo>
      </Provider>
    );
  }
}

export default App;


/*
            <ScrollUp ><a onClick={this.scrollToTop}>To the top!</a></ScrollUp>
*/