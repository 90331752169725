import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { CajaInput } from "../../components/Caja";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SelectDates extends Component {
  constructor() {
    super()
    this.state = {
      listaDates: []
    }
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };
  componentDidMount() {
    let myHeaders = new Headers({
      "token": this.props.auth.token,
      "user": this.props.auth.user._id,
      "explotacion": this.props.data.explotacion
    })
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/dates`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          listaDates: response
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (this.props.data.explotacion !== prevProps.data.explotacion) {
      this.componentDidMount()
    }
  }

  render() {
    const options = this.state.listaDates.map(item => (
      { key: item, value: item, text: item }
    ))
    return (
      <CajaInput>
        <Form onSubmit={this.props.handleSubmit}>
          <Form.Group widths="equal">
            <Form.Select
              label="Registro 1"
              name="anterior"
              options={options}
              onChange={this.props.handleSelect}
              placeholder="Registro 1"
            />
            <Form.Select
              label="Registro 2"
              name="presente"
              options={options}
              onChange={this.props.handleSelect}
              placeholder="Registro 2"
            />
          </Form.Group>
          <Form.Button
            primary
            onClick={this.props.handleSubmit}
            content="Comparar Datos"
          />
        </Form>
        <br />
      </CajaInput>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps)(SelectDates);
