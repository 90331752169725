import React from "react";
import { Grid } from "semantic-ui-react";
import { CajaInstrucciones } from "../../components/Caja";

function InstruccionesDNI() {
  return (
    <Grid.Row>
      <Grid.Column width="10">
        <CajaInstrucciones>
          <></>
        </CajaInstrucciones>
      </Grid.Column>
    </Grid.Row>
  );
}

export default InstruccionesDNI;
