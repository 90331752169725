import React, { Component } from "react"
import styled from "styled-components"

const Input = styled.input`
min-width: 40px;
`;


class EditableText extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            id: props.id,
            value: props.value,
            editClassName: props.editClassName,
            edit: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleFocus = this.handleFocus.bind(this)
        this.handleBlur = this.handleBlur.bind(this)
        this.handleKeyUp = this.handleKeyUp.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
        this.value = this.state.value;
    }
    handleFocus(e) {
        const value = e.target.value
        e.target.value = ''
        e.target.value = value
        this.setState({ backup: this.state.value })
    }
    handleBlur(e) {
        this.props.handleChange(this.props.id, this.props.name, e.target.value);
        this.setState({ edit: false })

    }
    handleKeyUp(e) {
        if (e.key === 'Escape') {
            this.setState({ edit: false, value: this.state.backup })
        }
        if (e.key === 'Enter') {
            this.props.handleChange(this.props.id, this.props.name, e.target.value);

            this.setState({
                edit: false,
                value: e.target.value
            })
        }
    }
    handleClick(e) {
        this.setState({ edit: this.state.edit !== true })
    }
    render() {
        return (
            <>{this.state.edit ?
                <Input
                    autoFocus
                    name={this.state.name}
                    type="text"
                    value={this.state.value}
                    className={this.state.editClassName}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyUp={this.handleKeyUp}
                />
                :
                <span onClick={this.handleClick}>
                    {this.state.value}
                </span>}
            </>
        )
    }
}

export default EditableText
