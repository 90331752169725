import React, { Component } from "react";
import { CajaTable } from "../../components/Caja";



class Table extends Component {

  render() {
    const tableProps = (
      <>
        <th>Fecha cambio</th>
        <th>Total</th>
        <th>Machos</th>
        <th>Hembras</th>
        <th>Bajas</th>
        <th>－ Salida</th>
        <th>－ Muerte</th>
        <th>Altas</th>
        <th>+ Entrada</th>
        <th>+ Autorreposición</th>
      </>
    );

    const obj = this.props.data.balanceList
    const obj2 = obj.sort((a, b) => (a.date > b.date) ? 1 : -1)
    const table = obj2.map(oveja => (
      <tr key={oveja.date}>
        <td>{oveja.date.length ? oveja.date : "-"}</td>
        <td>{oveja.OvejasAmbas.length ? oveja.OvejasAmbas : "-"}</td>
        <td>{oveja.Machos.length ? oveja.Machos : "-"}</td>
        <td>{oveja.Hembras.length ? oveja.Hembras : "-"}</td>
        <td>{oveja.OvejasFirst.length ? oveja.OvejasFirst : "-"}</td>
        <td>{oveja.bajaSalida.length ? oveja.bajaSalida : "-"}</td>
        <td>{oveja.bajaMuerte.length ? oveja.bajaMuerte : "-"}</td>
        <td>{oveja.OvejasSecond.length ? oveja.OvejasSecond : "-"}</td>
        <td>{oveja.altaEntrada.length ? oveja.altaEntrada : "-"}</td>
        <td>{oveja.altaAutorepo.length ? oveja.altaAutorepo : "-"}</td>

      </tr>
    ));
    return (
      <>
        <CajaTable>
          <div className="table-responsive-xl">
            <table className="table table-striped">
              <tbody>
                <tr>{tableProps}</tr>
                {table}
              </tbody>
            </table>
          </div>
        </CajaTable>
      </>
    );
  }
}
export default Table;


