import React, { Component } from "react";
import Dropzone from "./Dropzone";
import "./Upload.css";
import Progress from "./Progress";
import DownloadImg from "./DownloadImg.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react"


class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  onFilesAdded(files) {
    this.setState(prevState => ({
      files: prevState.files.concat(files)
    }));
  }

  async uploadFiles() {
    if (this.props.explotacion === "todas") {
      this.props.handleErrorMessage("Selecciona una explotación válida")
    } else if (this.props.explotacion === "") {
      this.props.handleErrorMessage("Introduce una explotación válida")
    } else {
      this.setState({ uploadProgress: {}, uploading: true });
      const promises = [];
      this.state.files.forEach(file => {
        promises.push(this.sendRequest(file));
      });
      try {
        await Promise.all(promises);

        this.setState({ successfullUploaded: true, uploading: false });
      } catch (e) {
        // Not Production ready! Do some error handling here instead...
        this.props.handleErrorMessage(e)

        this.setState({ successfullUploaded: true, uploading: false });
      }
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {

      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);
      req.open("POST", `${process.env.REACT_APP_API_URL}/api/ovejas/upload`);
      req.setRequestHeader("token", this.props.auth.token)
      req.setRequestHeader("user", this.props.auth.user._id)
      req.setRequestHeader("explotacion", this.props.explotacion)
      req.send(formData)
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={DownloadImg}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      return (
        <Button secondary size="medium"
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Limpiar
        </Button>
      );
    } else {
      return (
        <Button primary size="medium"
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={this.uploadFiles}
        >
          Confirmar
        </Button>
      );
    }
  }

  render() {
    const Contenido = (
      <div className="Upload">
        <span className="Title">Subir Archivos (.csv)</span>
        <div className="Content">
          <div>
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={this.state.uploading || this.state.successfullUploaded}
            />
          </div>
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
    return <>{this.props.isAuthenticated ? Contenido : ""} </>;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(mapStateToProps)(Upload);
