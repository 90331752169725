import React, { Component } from "react";
import Instructions from "./Instructions";
import SelectDates from "./SelectDates";
import SelectExplotacion from "./SelectExplotacion";
import Tables from "./Tables";
import ListaDates from "../../components/listaDates";
import { Grid } from "semantic-ui-react";
import ExportToCSV from "./ExportToCSV";
import ExportFullBook from "./ExportFullBook"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginHome from "../../components/auth/LoginHome"
import EditData from "../../components/EditData";
import ScreenMessage from "../../components/ScreenMessage";
import Loading from "../../components/Loading";


class Comparador extends Component {
  constructor() {
    super();
    this.state = {
      anterior: "",
      presente: "",
      explotacion: "todas",
      ovejasEnAmbos: [],
      ovejasEnFirst: [],
      ovejasEnSecond: [],
      ovejasEdit: [],
      editingData: false,
      loading: false,
      successmsg: "",
      errormsg: ""
    };
    this.handleErrorMessage = this.handleErrorMessage.bind(this);
    this.handleSuccessMessage = this.handleSuccessMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleEditData = this.handleEditData.bind(this);
    this.handleOvejasFieldValue = this.handleOvejasFieldValue.bind(this);
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  handleErrorMessage(msg) {
    this.setState({
      errormsg: msg
    })
    setTimeout(() => {
      this.setState({
        errormsg: ""
      })
    }, 4000);
  }
  handleSuccessMessage(msg) {
    this.setState({
      successmsg: msg
    })
    setTimeout(() => {
      this.setState({
        successmsg: ""
      })
    }, 4000);
  }

  handleOvejasFieldValue(id, name, value) {
    this.state.ovejasEdit.push({ _id: id, name: name, value: value })
    this.setState({ editingData: true })
  }

  handleEditData() {
    this.setState({ editingData: false })
  }

  handleSelect(e, { name, value }) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    this.setState({ loading: true })
    let myHeaders = new Headers({
      "token": this.props.auth.token,
      "user": this.props.auth.user._id,
      "explotacion": this.state.explotacion
    })
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    e.preventDefault();
    const { anterior, presente } = this.state;
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/${anterior}/${presente}`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          ovejasEnAmbos: response,
          loading: false
        });
      });
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/first/${anterior}/${presente}`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          ovejasEnFirst: response,
          loading: false
        });
      });
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/second/${anterior}/${presente}`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          ovejasEnSecond: response,
          loading: false
        });
      });
  }

  render() {
    const dataTodo = this.state.ovejasEnAmbos.concat(this.state.ovejasEnFirst).concat(this.state.ovejasEnSecond)
    const Contenido = (
      <>
        <Loading loading={this.state.loading} />
        <ScreenMessage successmsg={this.state.successmsg} errormsg={this.state.errormsg} />

        <Grid columns="equal" centered>
          <Grid.Column width="6">
            <ListaDates explotacion={this.state.explotacion} />
          </Grid.Column>
          <Grid.Column width="10">
            <Instructions />
          </Grid.Column>

          <Grid.Row>
            <Grid.Column>
              <SelectExplotacion
                handleSelect={this.handleSelect}
                handleSubmit={this.handleSubmit}
              />
              <SelectDates
                data={this.state}
                handleSelect={this.handleSelect}
                handleSubmit={this.handleSubmit}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width="15">
              {this.state.editingData ?
                <EditData
                  data={this.state}
                  handleEditData={this.handleEditData}
                  handleErrorMessage={this.handleErrorMessage}
                  handleSuccessMessage={this.handleSuccessMessage} />
                : ""}
              <Tables data={this.state} handleOvejasFieldValue={this.handleOvejasFieldValue} />
              <br />
            </Grid.Column>
            {dataTodo.length ?
              <>
                <ExportToCSV
                  data={this.state}
                  handleSuccessMessage={this.handleSuccessMessage} />
              </> : ""}
            <ExportFullBook
              data={this.state}
              auth={this.props.auth}
              handleErrorMessage={this.handleErrorMessage}
              handleSuccessMessage={this.handleSuccessMessage} />
          </Grid.Row>
        </Grid>
      </>
    );
    const Login = (
      <>
        <LoginHome />
      </>
    )
    return <>{this.props.isAuthenticated ? Contenido : Login} </>;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(mapStateToProps)(Comparador);
