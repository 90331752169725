import React, { Component } from "react";
import { CajaTable } from "../../components/Caja";



class Table extends Component {

  render() {
    const tableProps = (
      <>
        <th>ID</th>
        <th>Name</th>
        <th>UniqueDnis</th>
        <th>Bajas</th>
        <th>Diferencia</th>
      </>
    );

    const obj = this.props.data.datos
    const obj2 = obj.sort((a, b) => (a.name > b.name) ? 1 : -1)
    const table = obj2.map(datos => (
      <tr key={datos.id}>
        <td>{datos.id}</td>
        <td>{datos.name}</td>
        <td>{datos.uniquednis.length ? datos.uniquednis.length : "-"}</td>
        <td>{datos.bajas.length ? datos.bajas.length : "-"}</td>
        <td>{Number(datos.uniquednis.length - datos.bajas.length)}</td>


      </tr>
    ));
    return (
      <>
        <CajaTable>
          <div className="table-responsive-xl">
            <table className="table table-striped">
              <tbody>
                <tr>{tableProps}</tr>
                {table}
              </tbody>
            </table>
          </div>
        </CajaTable>
      </>
    );
  }
}
export default Table;


