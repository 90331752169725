import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import moment from "moment";

class ExportFullBook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
        this.Export = this.Export.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        if (this.props.data.explotacion === "todas") {
            this.props.handleErrorMessage("Debe seleccionar una explotación válida")
        } else {
            let myHeaders = new Headers({
                "token": this.props.auth.token,
                "user": this.props.auth.user._id,
                "explotacion": this.props.data.explotacion
            })
            let myInit = {
                method: 'GET',
                headers: myHeaders,
                mode: 'cors',
                cache: 'default'
            };
            fetch(`${process.env.REACT_APP_API_URL}/api/ovejas/getTodo`, myInit)
                .then(function (response) {
                    return response.json();
                })
                .then(response => {
                    this.setState({ data: response });
                }).then(this.Export).then(this.props.handleSuccessMessage(`Descargando libro digital de "${this.props.data.explotacion}"`)
                )
        }
    }

    Export() {
        const csvData = this.state.data
        var currentDate = moment().format("DD/MM/YYYY_LT");
        var csvRow = [];
        var A = [
            [
                "nº",
                "NºIdentificacion",
                "Raza",
                "Sexo",
                "Fecha_Identificacion",
                "Alta",
                "Causa_Alta",
                "Fecha_Alta",
                "Procedencia",
                "Baja",
                "Causa_Baja",
                "Fecha_Baja",
                "Destino"
            ]
        ];
        var re = csvData;
        for (var item = 0; item < re.length; item++) {
            A.push([
                item,
                re[item].dni,
                re[item].raza,
                re[item].sexo,
                re[item].fechaid,
                re[item].alta,
                re[item].causaalta,
                re[item].fechaalta,
                re[item].procedencia,
                re[item].baja,
                re[item].causabaja,
                re[item].fechabaja,
                re[item].destino
            ]);
        }
        for (var i = 0; i < A.length; ++i) {
            csvRow.push(A[i].join(","));
        }
        var csvString = csvRow.join("%0A");
        var a = document.createElement("a");
        a.href = `data:text/csv,` + csvString;
        a.target = "_Blank";
        a.download = `Libro_${this.props.data.explotacion}_${currentDate}.csv`;
        document.body.appendChild(a);
        a.click();
    }
    render() {
        return (
            <>
                <Button onClick={this.handleSubmit} color="green">
                    Descargar Libro Completo
                </Button>
            </>
        );
    }
}

export default ExportFullBook