import React from "react";
import { CajaInput } from "../../components/Caja";
import { Form } from "semantic-ui-react";

function InputBuscarDNI(props) {
  return (
    <CajaInput>
      <Form size="big" onSubmit={props.handleSubmit}>
        <Form.Group>
          <Form.Input
            label="NºIdentificación"
            name="dni"
            value={props.data.dni}
            onChange={props.handleChange}
            placeholder="NºIdentificación"
          />
        </Form.Group>
        <Form.Button
          primary
          onClick={props.handleSubmit}
          content="Buscar Registros"
        />
      </Form>
      <br />
    </CajaInput>
  );
}

export default InputBuscarDNI;
