import React from "react";
import { CajaTable } from "../../components/Caja";
import EditableText from "../../components/EditableText"
import EditableSelect from "../../components/EditableSelect"



function Table(props) {

  const optionsAlta = [
    { key: "A", value: "A", text: "A" },
    { key: "-", value: "-", text: "-" },
  ]
  const optionsCausaAlta = [
    { key: "A", value: "A", text: "A" },
    { key: "E", value: "E", text: "E" },
    { key: "-", value: "-", text: "-" },
  ]
  const optionsBaja = [
    { key: "B", value: "B", text: "B" },
    { key: "-", value: "-", text: "-" },
  ]
  const optionsCausaBaja = [
    { key: "M", value: "M", text: "M" },
    { key: "S", value: "S", text: "S" },
    { key: "-", value: "-", text: "-" },
  ]
  const tableProps = (
    <>
      <th>NºIdentificación</th>
      <th>Explotación</th>
      <th>Registro</th>
      <th>Alta</th>
      <th>Causa alta</th>
      <th>Fecha alta</th>
      <th>Procedencia</th>
      <th>Baja</th>
      <th>Causa baja</th>
      <th>Fecha baja</th>
      <th>Destino</th>
    </>
  );
  const tablaOvejaDNI = props.data.registros.map(oveja => (
    <tr key={oveja._id}>
      <td>{oveja.dni}</td>
      <td>{oveja.explotacion}</td>
      <td>{oveja.date}</td>
      <td><EditableSelect id={oveja._id} handleChange={props.handleOvejasFieldValue} name="alta" options={optionsAlta} value={oveja.alta.length ? oveja.alta : "-"} editClassName="form-control" /></td>
      <td><EditableSelect id={oveja._id} handleChange={props.handleOvejasFieldValue} name="causaalta" options={optionsCausaAlta} value={oveja.causaalta.length ? oveja.causaalta : "-"} editClassName="form-control" /></td>
      <td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="fechaalta" value={oveja.fechaalta.length ? oveja.fechaalta : "-"} editClassName="form-control" /></td>
      <td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="procedencia" value={oveja.procedencia.length ? oveja.procedencia : "-"} editClassName="form-control" /></td>
      <td><EditableSelect id={oveja._id} handleChange={props.handleOvejasFieldValue} name="baja" options={optionsBaja} value={oveja.baja.length ? oveja.baja : "-"} editClassName="form-control" /></td>
      <td><EditableSelect id={oveja._id} handleChange={props.handleOvejasFieldValue} name="causabaja" options={optionsCausaBaja} value={oveja.causabaja.length ? oveja.causabaja : "-"} editClassName="form-control" /></td>
      <td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="fechabaja" value={oveja.fechabaja.length ? oveja.fechabaja : "-"} editClassName="form-control" /></td>
      <td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="destino" value={oveja.destino.length ? oveja.destino : "-"} editClassName="form-control" /></td>
    </tr>
  ));
  return (
    <>
      <h2>Nº Registros en los que está: {props.data.registros.length}</h2>
      <CajaTable>
        <div className="table-responsive-xl">
          <table className="table table-striped">
            <tbody>
              <tr>{tableProps}</tr>
              {tablaOvejaDNI}
            </tbody>
          </table>
        </div>
      </CajaTable>
    </>
  );
}

export default Table;


/*
 const optionsSexo = [
    { key: "H", value: "H", text: "H" },
    { key: "M", value: "M", text: "M" },
    { key: "-", value: "-", text: "-" },
  ]
<th>Raza</th>
<th>Sexo</th>
<th>Fecha identificación</th>

<td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="raza" value={oveja.raza.length ? oveja.raza : "-"} editClassName="form-control" /></td>
<td><EditableSelect id={oveja._id} handleChange={props.handleOvejasFieldValue} name="sexo" options={optionsSexo} value={oveja.sexo.length ? oveja.sexo : "-"} editClassName="form-control" /></td>
<td><EditableText id={oveja._id} handleChange={props.handleOvejasFieldValue} name="fechaid" value={oveja.fechaid.length ? oveja.fechaid : "-"} editClassName="form-control" /></td>
*/