import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ExplotacionSelect extends Component {
    constructor() {
        super()
        this.state = {
            listaExplotaciones: []
        }
    }
    static propTypes = {
        auth: PropTypes.object.isRequired
    };
    componentDidMount() {
        let myHeaders = new Headers({
            "token": this.props.auth.token,
            "user": this.props.auth.user._id,
            "explotacion": this.props.explotacion
        })
        let myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(
            `${process.env.REACT_APP_API_URL}/api/ovejas/explotaciones`, myInit)
            .then(function (response) {
                return response.json();
            })
            .then(response => {
                this.setState({
                    listaExplotaciones: response
                });
            })
    }

    render() {
        const options = this.state.listaExplotaciones.map(item => (
            { key: item, value: item, text: item }
        ))
        return (
            <Form.Select
                label="Añadir Datos a Explotación:"
                name="explotacion"
                options={options}
                onChange={this.props.handleChange}
                placeholder="SELECCIONA EXPLOTACIÓN"
            />
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(ExplotacionSelect);
