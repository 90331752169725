import React, { Component } from "react";
import { Form } from "semantic-ui-react";

class ExplotacionSelect extends Component {
    render() {
        return (
            <Form.Input
                label="Añadir a Nueva Explotación:"
                placeholder="Nueva Explotación"
                name="explotacion"
                value={this.props.explotacion}
                onChange={this.props.handleChange} />
        );
    }
}

export default ExplotacionSelect;
