import styled from "styled-components";

export const CajaTable = styled.div`
display: flex;
  height:auto;
  flex-flow: auto;
  flex-grow: 1;
  flex-direction: column;
  ----;
  text-align: center;
  display: flex;
  ----;
  h2 {
    font-size: 1.8em;
  }
  font-size: 1.4em;
  white-space: nowrap;

`;

export const CajaInput = styled.div`
  height:auto;
  flex-flow: auto;
  flex-grow: 1;
  margin-left: auto;
  ----;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ----;
`;

export const CajaInstrucciones = styled.div`
  padding-top: 2%;
  h2 {
    font-size: 2em;
  }
  li {
    font-size: 1.3em;
  }
`;

export const CajaList = styled.div`
@media (min-width: 400px) {padding-right: 40%}
  height:auto;
  max-height: 20vh;
  min-height: 20vh;

  flex-direction: column;
  overflow: auto;
  flex-flow: auto;
  flex-grow: 1;
  ----;
  margin: auto;
  ----;
  align-items: center;
  text-align: right;
  font-weight: bold;
  font-size: 1.2em;
  list-style: none;
`;

// #282c34
//  background-color: #282c34;
