import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginHome from "../../components/auth/LoginHome"
import SelectExplotacion from "./SelectExplotacion"
import Table from "./Table";
import Loading from "../../components/Loading"



class BalanceApp extends Component {
    constructor() {
        super()
        this.state = {
            balanceList: [],
            explotacion: "Selecciona Explotacion",
            loading: false
        }
        this.handleSelect = this.handleSelect.bind(this)
    }
    static propTypes = {
        auth: PropTypes.object.isRequired
    };

    handleSelect(e, { name, value }) {
        this.setState({
            [name]: value,
            loading: true,
        });
    }
    componentDidUpdate(prevState) {
        if (this.state.explotacion !== "Selecciona Explotacion" && this.state.explotacion !== prevState.explotacion) {
            let myHeaders = new Headers({
                "token": this.props.auth.token,
                "user": this.props.auth.user._id,
                "explotacion": this.state.explotacion
            })
            let myInit = {
                method: 'GET',
                headers: myHeaders,
            };
            fetch(
                `${process.env.REACT_APP_API_URL}/api/ovejas/balance`, myInit)
                .then(async function (response) {
                    return await response.json();
                })
                .then(response => this.setState({
                    balanceList: response,
                    loading: false,
                    explotacion: "Selecciona Explotacion"
                }), console.log("Fetch Data", this.state.balanceList))
        } else (console.log("ComponentDidUpdate Balance"))
    }

    render() {
        const Contenido =
            <>
                {this.state.loading ? <Loading loading={this.state.loading} /> : ""}
                <div style={{ paddingTop: "30px" }}>
                    <Grid width="equal" centered>
                        <Grid.Row>
                            <SelectExplotacion
                                handleSelect={this.handleSelect}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width="15">
                                <Table data={this.state} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid >
                </div>
            </>
        const Login = (
            <>
                <LoginHome />
            </>
        )
        return <>{this.props.auth.isAuthenticated ? Contenido : Login} </>;
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(BalanceApp);