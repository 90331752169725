import React, { Component } from "react"
import { Dimmer, Loader } from "semantic-ui-react"
import styled from "styled-components"

const CajaSticky = styled.div`
position: fixed;
width: 100%;
height: 100%;
`;


class Loading extends Component {

    render() {
        return (
            <CajaSticky style={this.props.loading ? { zIndex: 9 } : { zIndex: 0 }} >
                {this.props.loading ?
                    <Dimmer active>
                        <Loader size="massive"> Cargando</Loader>
                    </Dimmer>
                    : ""}
            </CajaSticky>
        )
    }
}

export default Loading