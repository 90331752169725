import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import Table from "./Table";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginHome from "../../components/auth/LoginHome"
import Loading from "../../components/Loading"
import { login } from "../../actions/authActions";

class Admin extends Component {
    constructor() {
        super();
        this.state = {
            datos: [],
            loading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    static propTypes = {
        auth: PropTypes.object.isRequired
    };

    handleSubmit() {
        this.setState({ loading: true })
        let myHeaders = new Headers({
            "token": this.props.auth.token,
            "user": this.props.auth.user._id,
        })
        let myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(
            `${process.env.REACT_APP_API_URL}/api/ovejas/admin`, myInit)
            .then(function (response) {
                return response.json();
            })
            .then(response => {
                this.setState({
                    datos: response,
                    loading: false
                });
            });

    }

    render() {
        const Contenido = (
            <>
                <Loading loading={this.state.loading} />
                <Grid columns="equal" centered>
                    <Grid.Row style={{ paddingTop: "40px" }}>
                        <Button primary onClick={this.handleSubmit}>Request Data</Button>
                    </Grid.Row>
                    <Grid.Column width="15">
                        <Table data={this.state} />
                    </Grid.Column>
                </Grid>
            </>
        );

        const Login = (
            <>
                <LoginHome />
            </>
        )
        return <>{this.props.auth.isAuthenticated ? (this.props.auth.user._id === "5e1e0626c04c4b070e55b6ec" ? Contenido : <h1>Access Denied</h1>) : Login} </>;
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth
});

export default connect(mapStateToProps)(Admin);


// return <>{this.props.auth.user._id === "5e1e0626c04c4b070e55b6ec" ? Contenido : Login} </>;
