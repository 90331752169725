import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { CajaInput } from "../../components/Caja";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class SelectExplotacion extends Component {
    constructor() {
        super()
        this.state = {
            listaExplotaciones: []
        }
    }
    static propTypes = {
        auth: PropTypes.object.isRequired
    };
    componentDidMount() {
        let myHeaders = new Headers({
            "token": this.props.auth.token,
            "user": this.props.auth.user._id,
        })
        let myInit = {
            method: 'GET',
            headers: myHeaders,
            mode: 'cors',
            cache: 'default'
        };
        fetch(
            `${process.env.REACT_APP_API_URL}/api/ovejas/explotaciones`, myInit)
            .then(function (response) {
                return response.json();
            })
            .then(response => {
                this.setState({
                    listaExplotaciones: response
                });
            })
    }

    render() {
        const todas = { key: "todas", value: "todas", text: "Todas" }
        const mapLista = this.state.listaExplotaciones.map(item => (
            { key: item, value: item, text: item }
        ))
        const options = mapLista.concat(todas)
        return (
            <CajaInput>
                <Form onSubmit={this.props.handleSubmit}>
                    <Form.Select
                        label="Explotación"
                        name="explotacion"
                        options={options}
                        onChange={this.props.handleSelect}
                        placeholder="Por defecto: Todas"
                    />
                </Form>
                <br />
            </CajaInput>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps)(SelectExplotacion);
