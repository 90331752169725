import React, { Component } from "react";
import { Form, Grid } from "semantic-ui-react";
import { Alert } from "reactstrap"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/authActions";
import { clearErrors } from "../../actions/errorActions";
import styled from "styled-components"
import { Input, Label } from "reactstrap"
import RegisterModal from "./RegisterModal";

const CajaDos = styled.div`
align-items: center;
text-align: center;`;
const Caja = styled.div`
align-items: center;
text-align: center;
margin: 15% auto 0 auto;
font-size: 1.5em;
li {
  margin: 10px 50px;
  list-style: none;
  font-size: 1.5em;
}
`;

class LoginHome extends Component {
  state = {
    email: "",
    password: "",
    msg: null
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    const { error } = this.props;
    if (error !== prevProps.error) {
      // Check for register error
      if (error.id === "LOGIN_FAIL") {
        this.setState({ msg: error.msg.msg });
      } else {
        this.setState({ msg: null });
      }
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const user = {
      email,
      password
    };

    // Attempt to login
    this.props.login(user);
  };

  render() {
    return (
      <>
        <Caja>
          <Grid centered>
            <Grid.Column width="16">
              <CajaDos>
                {this.state.msg ? (
                  <Alert color="danger">{this.state.msg}</Alert>
                ) : null}
                <Form onSubmit={this.onSubmit} widths="equal">

                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    className="mb-3"
                    onChange={this.onChange}
                  />

                  <Label for="password">Contraseña</Label>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Contraseña"
                    className="mb-3"
                    onChange={this.onChange}
                  />
                  <Form.Button primary content="Iniciar sesión" />
                </Form>
              </CajaDos>
            </Grid.Column>
            <Grid.Row>
              <RegisterModal />
            </Grid.Row>
          </Grid>
        </Caja>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error
});

export default connect(mapStateToProps, { login, clearErrors })(LoginHome);
