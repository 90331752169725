import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import moment from "moment";

class ExportToCSV extends Component {
  constructor(props) {
    super(props);
    this.Export = this.Export.bind(this);
  }

  Export() {
    var currentDate = moment().format("DD-MM-YYYY_LT");
    const csvData = this.props.data.ovejasEnFirst
      .concat(this.props.data.ovejasEnSecond)
      .concat(this.props.data.ovejasEnAmbos);

    var csvRow = [];
    var A = [
      [
        "nº",
        "NºIdentificacion",
        "Raza",
        "Sexo",
        "Fecha_Identificacion",
        "Alta",
        "Causa_Alta",
        "Fecha_Alta",
        "Procedencia",
        "Baja",
        "Causa_Baja",
        "Fecha_Baja",
        "Destino"
      ]
    ];

    var re = csvData;
    for (var item = 0; item < re.length; item++) {
      A.push([
        item,
        re[item].dni,
        re[item].raza,
        re[item].sexo,
        re[item].fechaid,
        re[item].alta,
        re[item].causaalta,
        re[item].fechaalta,
        re[item].procedencia,
        re[item].baja,
        re[item].causabaja,
        re[item].fechabaja,
        re[item].destino
      ]);
    }
    for (var i = 0; i < A.length; ++i) {
      csvRow.push(A[i].join(","));
    }
    var csvString = csvRow.join("%0A");
    var a = document.createElement("a");
    a.href = `data:text/csv,` + csvString;
    a.target = "_Blank";
    a.download = `Comparacion${this.props.data.anterior}y${this.props.data.presente}_${currentDate}.csv`;
    document.body.appendChild(a);
    a.click();
    this.props.handleSuccessMessage(`Descargando comparación "${this.props.data.anterior}" y "${this.props.data.presente}"`)
  }
  render() {
    return (
      <>
        <Button onClick={this.Export} color="green">
          Descargar Comparación
        </Button>
      </>
    );
  }
}

export default ExportToCSV;
