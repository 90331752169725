import React from "react";
import { Grid } from "semantic-ui-react";
import { CajaInstrucciones } from "../../components/Caja";

function DatosComparador() {
  return (
    <Grid.Row>
      <Grid.Column width="10">
        <CajaInstrucciones>
          <h2>Introduce los dos registros que quieres comparar.</h2>
        </CajaInstrucciones>
      </Grid.Column>
    </Grid.Row>
  );
}

export default DatosComparador;
