import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/authActions";
import PropTypes from "prop-types";

export class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired
  };

  render() {
    return (
      <Fragment>
        <li onClick={this.props.logout}>
          {/*eslint-disable-next-line */}
          <a href="#">Cerrar sesión</a>
        </li>
      </Fragment>
    );
  }
}

export default connect(null, { logout })(Logout);
