import React, { Component } from "react"
import { Button } from "semantic-ui-react"
import styled from "styled-components"
import axios from "axios"

const CajaSticky = styled.div`
display: flex;
position: sticky;
top: 30px
z-index:4
`;

const MyButton = styled.div`
    display: inline-block;
    margin-left: auto;
`;

class EditData extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        e.preventDefault()
        const config = { headers: { "Content-Type": "application/json" } };
        const body = this.props.data.ovejasEdit;
        axios.patch(`${process.env.REACT_APP_API_URL}/api/ovejas/editdata`, body, config)
            .catch(error =>
                this.props.handleErrorMessage("No se han podido guardar los cambios")
            )
        this.props.handleSuccessMessage("Cambios Guardados");
        this.props.handleEditData()
    }


    render() {
        return (
            <CajaSticky>
                <MyButton>
                    <Button color="red" onClick={this.handleClick} content='Confirmar Cambios' />
                </MyButton>
            </CajaSticky>
        )
    }
}

export default EditData