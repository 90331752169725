import React, { Component } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Container, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import RegisterModal from "./auth/RegisterModal";
import LoginModal from "./auth/LoginModal";
import Logout from "./auth/Logout";

const MenuDesktop = styled.ul`
  @media (max-width: 750px) {
    display: none;}

  @media not all and (max-width: 750px) {
    display: flex;}
  
  width: 100%;
  background-color: #1e76c9;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 6px;
  li {
    padding: 10px 10px;
    white-space: nowrap;
    list-style: none;
    font-weight: 1000;
    text-decoration: none;
    a {
      color: white;
      font-weight: 1000;
      text-decoration: none;
    }}

  li.Active {
    border-bottom: 3px solid white;}
`;
const MenuMobile = styled.div`
@media (max-width: 800px) {
  font-size: 2vh;}
  
@media not all and (max-width: 750px) {
  display: none;}
@media (max-width: 750px) {
  display: flex;}

li {
  margin: auto 5px;
  white-space: nowrap;
  list-style: none;
  font-weight: 1000;
  text-decoration: none;
  a {
    color: white;
    font-weight: 1000;
    text-decoration: none;
  }}
  justify-content: space-between;
  padding: 10px 40px 10px 20px;
  background-color: #1e76c9;
  position: relative;
  overflow: hidden;
  flex-flow: row;
  flex-grow: 1;
`;

const ListaMenu = styled.li`
  padding: 15px 20px;
  list-style: none;

  a {
    color: #1e76c9;
    font-weight: 1000;
    text-decoration: none;
  }
  li.Active {
    border-left: 4px solid #b6090f;
    padding-left: 4px;
  }
`;

class MenuApp extends Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { location } = this.props;
    const SectionsData = [
      {
        key: "1",
        text: "Comparador",
        href: "/"
      },
      {
        key: "2",
        text: "Buscar por Nº",
        href: "/buscarpordni"
      },
      {
        key: "3",
        text: "Balance",
        href: "/balance"
      },
      {
        key: "4",
        text: "Añadir datos",
        href: "/upload"
      }
    ];

    const SectionsDesktop = SectionsData.map(item => (
      <li
        key={item.key}
        className={location.pathname === `${item.href}` ? "Active" : ""}
      >
        <a href={item.href}> {item.text}</a>
      </li>
    ));
    const SectionsMobile = SectionsData.map(item => (
      <ListaMenu key={item.key}
        className={location.pathname === `${item.href}` ? "Active" : ""}
      >
        <a href={item.href}> {item.text}</a>
      </ListaMenu>
    ));

    const authMenu = (
      <>
        <MenuDesktop>
          <Container fluid />
          {SectionsDesktop}
          <Container fluid />
          <>
            <li>{user ? `Bienvenido ${user.name}` : ""}</li>
            <Logout />
          </>
        </MenuDesktop>
        <MenuMobile>
          <Icon
            className="IconMenu"
            onClick={this.toggle}
            name="bars"
            size="big"
          />
          <Modal
            isOpen={this.state.isOpen}
            toggle={this.toggle}
            className="MarginTop"
          >
            <ModalHeader>
              Menú
              <ModalBody>{SectionsMobile}</ModalBody>
            </ModalHeader>
          </Modal>
          <Container />
          <li>{user ? `Bienvenido ${user.name}` : ""}</li>
          <Logout />
        </MenuMobile>
      </>
    );

    const guestMenu = (
      <>
        <MenuDesktop>
          <Container fluid />
          <RegisterModal />
          <LoginModal />
        </MenuDesktop>
        <MenuMobile>
          <Container fluid />
          <RegisterModal />
          <LoginModal />
        </MenuMobile>
      </>
    );

    return <>{isAuthenticated ? authMenu : guestMenu}</>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const MenuWithRouter = withRouter(MenuApp);
export default connect(mapStateToProps, null)(MenuWithRouter);