import React, { Component } from "react";
import { CajaList, CajaInstrucciones } from "./Caja";
import { connect } from "react-redux";
import PropTypes from "prop-types";


class ListaDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaFechas: []
    };
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  componentDidMount() {
    let myHeaders = new Headers({
      "token": this.props.auth.token,
      "user": this.props.auth.user._id,
      "explotacion": this.props.explotacion
    })
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/dates`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          listaFechas: response
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (this.props.explotacion !== prevProps.explotacion) {
      this.componentDidMount()
    }
  }

  render() {
    const Lista = this.state.listaFechas.map(item => (
      <li key={item}>{item}</li>
    ));
    return (
      <CajaList>
        <CajaInstrucciones>
          <h2>Registros:</h2>
          {this.state.listaFechas.length ? Lista : "No hay datos"}
        </CajaInstrucciones>
      </CajaList>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(ListaDates);
