import React, { Component } from "react";
import InputBuscarDNI from "./InputBuscarDNI";
import { Grid } from "semantic-ui-react";
import InstruccionesDNI from "./InstruccionesDNI";
import TableDNI from "./TableDNI";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginHome from "../../components/auth/LoginHome"
import EditData from "../../components/EditData";
import Loading from "../../components/Loading"

class BuscarPorDNI extends Component {
  constructor() {
    super();
    this.state = {
      dni: "",
      registros: [],
      ovejasEdit: [],
      editing: false,
      loading: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditData = this.handleEditData.bind(this);
    this.handleOvejasFieldValue = this.handleOvejasFieldValue.bind(this);
  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };
  handleOvejasFieldValue(id, name, value) {
    console.log(id, name, value)

    this.state.ovejasEdit.push({ _id: id, name: name, value: value })
    this.setState({ editing: true })
  }
  handleEditData() {
    this.setState({ editing: false })
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    this.setState({ loading: true })
    e.preventDefault();
    let myHeaders = new Headers({
      "token": this.props.auth.token,
      "user": this.props.auth.user._id,
      "dni": this.state.dni
    })
    let myInit = {
      method: 'GET',
      headers: myHeaders,
      mode: 'cors',
      cache: 'default'
    };
    fetch(
      `${process.env.REACT_APP_API_URL}/api/ovejas/getdni`, myInit)
      .then(function (response) {
        return response.json();
      })
      .then(response => {
        this.setState({
          registros: response,
          loading: false
        });
      });
  }

  render() {
    const Contenido = (
      <>
        <Loading loading={this.state.loading} />
        <Grid columns="equal" centered>

          <Grid.Column width="11">
            <InstruccionesDNI />
          </Grid.Column>

          <Grid.Row>
            <InputBuscarDNI
              data={this.state}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
            />
          </Grid.Row>
          <Grid.Column width="15">
            {this.state.editing ?
              <EditData data={this.state} handleEditData={this.handleEditData} />
              : ""}
            <TableDNI data={this.state} handleOvejasFieldValue={this.handleOvejasFieldValue} />
          </Grid.Column>
        </Grid>
      </>
    );

    const Login = (
      <>
        <LoginHome />
      </>
    )
    return <>{this.props.isAuthenticated ? Contenido : Login} </>;
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth
});

export default connect(mapStateToProps)(BuscarPorDNI);
