import React, { Component } from "react";
import "./UploadApp.css";
import Upload from "./Upload";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoginHome from "../../components/auth/LoginHome"
import { Button, Form, Grid } from "semantic-ui-react"
import ExplotacionSelect from "./ExplotacionSelect"
import ExplotacionInput from "./ExplotacionInput"
import ScreenMessage from "../../components/ScreenMessage";

const TopMargin = styled.div`
padding: 6% 0 0 0`;

class UploadApp extends Component {
  constructor() {
    super()
    this.state = {
      explotacion: "todas",
      errormsg: "",
      creatingNew: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleErrorMessage = this.handleErrorMessage.bind(this)

  }
  static propTypes = {
    auth: PropTypes.object.isRequired
  };

  handleErrorMessage(msg) {
    this.setState({
      errormsg: msg
    })
    setTimeout(() => {
      this.setState({
        errormsg: ""
      })
    }, 4000);
  }

  handleChange(e, { name, value }) {
    this.setState({
      [name]: value,
    });
  }

  handleClick() {
    this.setState({
      creatingNew: !this.state.creatingNew,
      explotacion: ""
    })
  }

  render() {
    const Contenido = (
      <>
        <ScreenMessage errormsg={this.state.errormsg} />
        <TopMargin>

          <Grid columns="equal" centered>

            <Grid.Row>
              <Form size="big">
                {this.state.creatingNew ?
                  <Form.Button color="red" onClick={this.handleClick} content="Cancelar" />
                  :
                  <Form.Button color="green" icon="plus" onClick={this.handleClick} content="Crear nueva explotación" />}

                {this.state.creatingNew ?
                  <ExplotacionInput explotacion={this.state.explotacion} handleChange={this.handleChange} />
                  :
                  <ExplotacionSelect explotacion={this.state.explotacion} handleChange={this.handleChange} />}
              </Form>
            </Grid.Row>

            <Grid.Row>
              <div className="Card">
                <Upload explotacion={this.state.explotacion} handleErrorMessage={this.handleErrorMessage} />
              </div>
            </Grid.Row>

            <Button size="big" color="green" as="a" href="/Plantilla.csv" target="_blank" download>Descargar Plantilla de Datos</Button>
          </Grid >
        </TopMargin>
      </>
    );

    const Login = (
      <>
        <LoginHome />
      </>
    )
    return <>{this.props.auth.isAuthenticated ? Contenido : Login} </>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,

});

export default connect(mapStateToProps)(UploadApp);